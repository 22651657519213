@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #151515;
}
.flex-all-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-all-center-col{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-space-between{
  display: flex;
  justify-content: space-between;
}
.flex-space-evenly{
  display: flex;
  justify-content: space-evenly;
}
.hero-section{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-with-hero{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.hero-section-hello{
  text-align: left;
  font-weight: 400;
  font-size: 40px;
  margin-bottom: 0px;
  display: flex;
  color:white;
}
.floating-hand{
  animation: float 2s ease-in-out infinite;
  transform: translateY(0px);  
  margin-left: 10px;
}
@keyframes float {
	0% {
		transform: translateY(0px);
	}
  50%{
    transform: translateY(-10px);
  }
	100% {
		transform: translateY(0px);
	}
}
.center-img{
  text-align: center;
}
.introduction{
  margin-top: 60px;
  text-align: left;
  font-weight: 300;
  font-size: 18px;
  letter-spacing: -0.4px;
  display: flex;
  color:white;
  margin-bottom: 40px;
}
.main-button{
  background-color: #219B05;
  border:1px solid #219B05;
  text-decoration: none;
  color: white;
  padding:15px 20px;
  font-size: 16px;
  font-weight: 300;
  width: 190px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
}
.main-button:hover{
  background-color: #177203;
  border: 1px solid #177203;
  color:white !important;
}
.main-outline-button{
  border-radius: 5px;
  border:1px solid #219B05;
  text-decoration: none;
  color: white;
  padding:15px 20px;
  font-weight: 300;
  font-size: 16px;
  width: 190px;
  text-align: center;
  transition: 0.3s;
}
.main-outline-button:hover{
  background-color: #1b1b1b;
  color:white !important;
}
.section-heading{
  font-size: 50px;
  color:white;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.4px;
}
.section-heading-sm{
  font-size: 35px;
  color:white;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.4px;
}
.section-start{
  padding:120px 0px;
}
.section-end{
  padding-bottom: 120px;
}
.green-span{
  color:#219B05;
}
.experience-main{
  background-color: #232323;
  width: 100%;
  border-radius: 5px;
  padding:40px 20px;
  position: relative;
  margin-bottom: 40px;
}
.experience-main .my-company-name{
  font-size: 25px;
  color: white;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 30px;
  font-weight: 600;
}
.experience-main .duration{
  font-size: 16px;
  color: white;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 300;
  opacity: 0.6;
}
.experience-main .my-company-description{
  font-size: 16px;
  color: white;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 70%;
  font-weight: 300;
}
.pulse-main{
  position: absolute;
  top:20px;
  left:20px;
}
.pulse-main .circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #219B05;
}
.pulse-main .green{
  background-color: #219B05;
}
.pulse-main .pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(33, 155, 5, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(33, 155, 5, 0.2);
  }
}
.small-heading{
  font-size: 30px;
  color:white;
  margin-bottom: 60px;
  margin-top: 30px;
  text-align: center;
  letter-spacing: -0.4px;
  opacity: 0.7;
}
.experience-head{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.previous-experience{
  position: absolute;
  z-index: 1;
}
.experience-box{
  z-index: 2;
  position: relative;
  background-color: #232323;
  width: 100%;
  border-radius: 5px;
  margin-top: 60px;
  padding:30px;
}
.experience-box .company-name{
  font-size: 24px;
  color:white;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}
.experience-box .work-duration{
  font-size: 16px;
  color:white;
  margin-bottom: 0px;
  font-weight: 400;
  text-align: left;
}
.experience-box .work-role{
  font-size: 16px;
  color:white;
  margin-bottom: 0px;
  font-weight: 400;
  text-align: right;
}

.awards-section{
  background-color: #232323;
}
.awards{
  background-color: #151515;
  border-radius: 5px;
  padding:30px;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 60px;
}
.awards .medal-picture{
  width: 120px;
  height: 120px;
}
.winner{
  font-size: 16px;
  color:#36d014;
  margin-top: 30px;
  margin-bottom: 0px;
  font-weight: 300;
  text-align: center;
}
.one{
  position:absolute;
  z-index: 2;
  left:-40px;
}
.award-name{
  font-size: 16px;
  color:white;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 300;
  text-align: center;
}
.awards-2{
  margin-top: 60px;
}
.projects{
  background-color: #232323;
  padding-top: 60px;
  margin-bottom: 50px;
  border-radius: 10px;
}
.projects .project-name{
  font-size: 30px;
  color:white;
  margin-top: 0px;
  margin-bottom: 30px;
  font-weight: 600;
  text-align: center;
}
.projects .project-description{
  font-size: 16px;
  color:white;
  margin-top: 0px;
  margin-bottom: 30px;
  font-weight: 300;
  text-align: center;
  width: 50%;
}
.projects .project-img{
  margin-top: 40px;
}
.toolbar{
  /* position:sticky; */
  width:100%;
  background-color:rgba(21, 21, 21, 0.9);
  /* background-color: transparent; */
  position: absolute;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  height:70px;
  top:0px;
  left:0px;
  z-index:899;
}
.toolbar .logo-img{
  width: 40px;
  height: 40px;
}
.toolbar .toolbar-navigation{
  display:flex;
  align-items:center;
  height:100%;
  padding:10px 25px;
}
.toolbar .toolbar-navigation-items ul{
  list-style: none;
  margin:0;
  padding:0;
  display:flex;
}
.toolbar .toolbar-navigation-items li{
  padding: 5px 15px;
}
.toolbar .toolbar-navigation-items li img{
  margin-left:10px;
}
.toolbar .toolbar-logo{
  margin-left:10px;
}
.toggle-button{
  background-color:transparent;
  display:none;
  flex-direction: column;
  border:none;
  cursor: pointer;
}
.toggle-button:focus{
  outline:none;
}
.toolbar .toolbar-logo a{
  text-decoration: none;
}
.toolbar .toolbar-navigation-items a{
  text-decoration: none;
  color:#fff;
  font-size:14px;
  font-weight: 300;
  transition: 0.3s;
  border-bottom:3px solid #161B22;
}
.toolbar .toolbar-navigation-items a:hover,
.toolbar .toolbar-navigation-items a:active{
  border-bottom:3px solid #00D1A1;
  /* color:#00D1A1; */
  /* background:#0D5544; */
}
.side-drawer{
  height:100%;
  background-color: #05080d;
  position: fixed;
  top:0;
  left:0;
  width:70%;
  max-width:400px;
  z-index:1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}
.side-drawer.open{
  transform: translateX(0px);
  box-shadow: 2px 0px 20px rgba(0,0,0,0.43);
}
.side-drawer ul{
  display:flex;
  flex-direction: column;
  list-style: none;
  justify-content: center;
  height: 100%;
  padding:0px 10px;
}
.side-drawer li{
  margin:10px 0px;
  padding:10px 8vh;
  width:100%;
}
.side-drawer a{
  color:#d9d9d9;
  background:#05080d;
  transition:0.2s;
  border-radius:3px;
}
.side-drawer a:hover{
  text-decoration: none;
  background:#048063;
  color:#d9d9d9;
  cursor: pointer;
}
.backdrop{
  position:fixed;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.3);
  z-index:999;
  top:0;
  left:0;
}
.spacer{
  flex-grow:1;
}










.second-hero-section{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/img/second-home-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.second-hero-heading{
  font-size: 50px;
  font-weight: 700;
  color:white;
  text-align: center;
  margin-bottom: 10px;
}
.second-hero-subheading{
  font-size: 50px;
  font-weight: 700;
  color:white;
  opacity: 0.7;
  text-align: center;
  margin-bottom: 40px;
}
.second-hero-paragraph{
  font-size: 16px;
  font-weight: 300;
  color:white;
  text-align: center;
  margin-bottom: 10px;
}
.second-main-button{
  background-color: transparent;
  border:1px solid #77F859;
  text-decoration: none;
  color: white;
  padding:16px 20px;
  font-size: 16px;
  font-weight: 500;
  width: 225px;
  border-radius: 5px;
  text-align: center;
  transition: 0.3s;
  margin-top: 30px;
}
.second-main-button:hover{
  background-color:rgba(119, 248, 89, 0.15);
  border: 1px solid #77F859;
  color:white !important;
}
.second-about-text{
  font-size: 18px;
  font-weight: 300;
  color:white;
  text-align: left;
  opacity: 0.7;
  margin-top:40px;
  margin-bottom: 40px;
}
.experience-bg{
  background-image: url(/public/img/experience-bg.svg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.second-experience-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.experience-duration{
  font-size: 14px;
  color:white;
  opacity: 0.6;
  font-weight: 300;
  text-align: right;
  margin-bottom: 0px;
}
.experience-list{
  padding: 20px;
  background-color: #151515;
  border-bottom: 1px solid rgba(119, 248, 89, 0.7);
  margin-block: 30px;
  border-radius: 10px;
  z-index: 2;
  position: relative;
}
.experience-list:hover .experience-overlay{
  top: 0;
  height: 100%;
  z-index: 3;
  opacity: 1;
}
.experience-list .experience-container{
  position: relative;
  z-index: 10;
}
.experience-list .experience-overlay{
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #232323;
  border: 1px solid rgba(119, 248, 89, 0.7);
  opacity: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
  border-radius: 10px;
}
.experience-name{
  font-size:20px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: left;
  color:white;
  line-height: 50px;
}
.experience-company{
  margin-bottom: 0px;
  text-align: left;
  color:#77F859;
}
.experience-detail{
  padding:0px 15px;
  margin:20px 0px;
  display: flex;
  align-items: flex-start;
}
.experience-roles{
  font-size:16px;
  margin-left: 15px;
  font-weight: 300;
  margin-bottom: 0px;
  text-align: left;
  color:white;
  opacity: 0.7;
  letter-spacing: -0.2px;
}
.grey-section{
  background-color: #232323;
}
.education-section{
  background-image: url(/public/img/education.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.projects-section{
  background-image: url(/public/img/projects-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.education-list{
  padding:30px;
  margin-bottom: 30px;
  border-radius: 5px;
  border:1px solid #151515;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  height: 300px;
}
.education-list:hover{
  border:2px solid rgba(119, 248, 89, 0.7);
  cursor: pointer;
  transform: translateY(-10px);
}
.education-list .education-img{
  width:35px;
  height: 35px;
  margin-bottom: 20px;
}
.education-list .education-details{
  width: 100%;
}
.education-list .education-name{
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0px;
  color:white;
}
.education-list .education-degree{
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 0px;
  color:white;
  margin-top: 15px;
}
.education-list .education-duration{
  display: flex;
  justify-content: space-between;
}
.education-list .education-duration .education-from-to{
  font-size: 14px;
  font-weight: 300;
  color:white;
  margin-bottom: 0px;
}
.opacity-07{
  opacity: 0.7;
}
.featured-award-box{
  padding:40px 20px;
  margin-top: -200px;
  border-radius: 10px;
}
.featured-award-box .featured-award-info{
  font-size: 16px;
  font-weight: 300;
  color: white;
  text-align: center;
  margin-bottom: 0px;
  width: 80%;
}
.other-awards{
  border-radius: 10px;
  padding:25px;
  transition: 0.3s;
  max-height:350px;
  height: 350px;
}
.other-awards .other-awards-img{
  width: 35px;
  height: 35px;
  margin-bottom: 25px;
}
.other-awards .other-awards-name{
  font-size: 24px;
  font-weight: 500;
  color: white;
  text-align: left;
  margin-bottom: 10px;
  opacity: 1;
}
.other-awards .other-awards-rank{
  font-size: 16px;
  font-weight: 300;
  color: white;
  text-align: left;
  margin-bottom: 0px;
  opacity: 0.8;
}
.other-awards .other-awards-description{
  margin-top: 30px;
}
.projects-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.project-img{
  width: 100%;
}
.projects-box .project-name{
  font-size: 25px;
  color: white;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
}
.projects-box .project-description{
  font-size: 16px;
  color: white;
  text-align: left;
  margin-bottom: 0px;
  font-weight: 300;
}
.projects-links{
  display: flex;
  margin-top: 20px;
  align-items: center;
}
.projects-links .links{
  width: 20px;
  height: 20px;
  margin-left:10px;
}
.project-tech{
  display: flex;
}
.project-tech .project-tech-name{
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 10px;
  margin-bottom: 0px;
  margin-top: 20px;
}
.project-tech .project-tech-name:nth-child(1){
  margin-left: 0px;
}
.projects-box .featured-project{
  font-size: 14px;
  font-weight: 300;
  color:#77F859;
  margin-bottom: 5px;
  opacity: 0.6;
}
.similar-projects{
  background-color:#232323;
  padding:40px 30px;
  height: 350px;
  margin-bottom: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  border:1px solid #232323;
}
.similar-projects:hover{
  cursor: pointer;
  border:1px solid #77F859;
  transform: translateY(-10px);
}
.similar-projects:hover .project-name{
  color:#77F859; 
}
.similar-projects .project-name{
  font-size: 24px;
  font-weight: 600;
  color:white;
  margin-bottom: 0px;
  text-align: left;
  transition: 0.3s;
}
.similar-projects .project-description{
  font-size: 14px;
  font-weight: 300;
  color:white;
  margin-top: 15px;
  margin-bottom: 0px;
  text-align: left;
}
.similar-projects .tech-names{
  color:#77F859;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 300;
  margin-left:10px;
  margin-bottom: 0px;
  letter-spacing: -0.4px;
}
.similar-projects .tech-names:first-child{
  margin-left:0px
}
.similar-projects .project-links{
  width: 20px;
  height: 20px;
  opacity: 0.7;
}
.footer-section{
  background-color: white;
  padding:120px 0px;
}
.footer-heading{
  font-size: 5vw;
  color:#05080d;
  text-align: center;
  margin-bottom: 0px;
  font-weight: 600;
  letter-spacing: -0.3vw;
}
.flex-align-items-center{
  display: flex;
  align-items: center;
}



@media (max-width:460px) {
  .toggle-button{
    display: flex;
  }
  .section-start{
    padding:80px 0px;
  }
  .second-hero-heading, .second-hero-subheading, .section-heading{
    font-size: 35px;
  }
  .second-about-text{
    font-size: 16px;
    margin-top: 0px;
  }
  .second-experience-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .experience-name{
    font-size: 17px;
    line-height: 30px;
  }
  .experience-roles{
    font-size: 14px;
    opacity: 1;
  }
  .featured-award-box{
    margin-top: 0px;
  }
  .featured-award-box .featured-award-info{
    font-size: 14px;
    width: 100%;
  }
  .second-main-button{
    font-size: 14px;
    width: 180px;
  }
  .other-awards{
    margin-bottom: 20px;
  }
  .other-awards .other-awards-rank{
    font-size: 14px;
  }
  .other-awards .other-awards-description{
    opacity: 1;
  }
  .projects-box{
    background-color: #232323;
    padding:20px;
    border-radius: 5px;
    margin-bottom: 20px;
    height: auto;
  }
  .project-img{
    margin-bottom: 20px;
  }
  .projects-box .project-description{
    font-size: 14px;
  }
  .reverse{
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-heading{
    font-size: 35px;
  }
}